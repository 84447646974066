// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
$md-crewcus: ( 50: #91f9de, 100: #81e7cc, 200: #5bedc7, 300: #07d6a0, 400: #07d6a0, 500: #07d6a0, 600: #07d6a0, 700: #07d6a0, 800: #07d6a0, 900: #07d6a0, A100: #07d6a0, A200: #07d6a0, A400: #07d6a0, A700: #07d6a0, contrast: ( 50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff, ));
$md-crewcusyellow: ( 50: #fbe272, 100: #ffe05a, 200: #ffdf54, 300: #ffd002, 400: #ffd002, 500: #ffd002, 600: #ffd002, 700: #ffd002, 800: #ffd002, 900: #ffd002, A100: #ffd002, A200: #ffd002, A400: #ffd002, A700: #ffd002, contrast: ( 50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff, ));
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hotseat-primary: mat-palette($md-crewcus);
$hotseat-accent: mat-palette($md-crewcusyellow, A200, A100, A400);
// The warn palette is optional (defaults to red).
$hotseat-warn: mat-palette($mat-red);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hotseat-theme: mat-light-theme(( color: ( primary: $hotseat-primary, accent: $hotseat-accent, warn: $hotseat-warn, )));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($hotseat-theme);
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import "global-colors.scss";
body {
    font-family: "Open Sans", sans-serif;
    margin: 0 !important;
    font-size: 14px;
    padding: 0 !important;
    overscroll-behavior-y: none;
    background: white;
    background-size: cover;
}

.flex {
    display: flex;
}

input,
select {
    font-family: "Open Sans", sans-serif !important;
    font-size: 12px !important;
}

h2 {
    font-weight: normal;
    margin-block-end: 0;
    color: #3e3e3e;
}

h3 {
    font-weight: normal;
    margin-block-end: 0;
    margin-block-start: 5px;
    color: #c4c4c4;
    font-size: 18px;
}

a,
a:visited {
    font-size: 13px;
    text-decoration: none;
    color: #3e3e3e;
}

@keyframes spin {
    from {
        transform: rotate(120deg);
    }
    to {
        transform: rotate(480deg);
    }
}

.spin {
    animation: 0.8s spin ease-in-out infinite;
}

button:disabled {
    opacity: 0.15;
}

.header {
    height: 50px;
    display: flex;
    padding: 0px 50px 0px 15px;
    line-height: 50px;
    background: white;
}

.header.shadow {
    box-shadow: 0 19px 38px rgb(255 255 255 / 30%), 5px 9px 12px rgb(154 154 154 / 10%);
}

.header h1 {
    background: linear-gradient(90deg, #525561 0%, #fa585f 80%, #fcb045 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
    margin-block-start: 0;
    margin-block-end: 0;
    cursor: pointer;
    outline: 0;
}

.header h1:focus {
    outline: 0;
}

.header a {
    margin-left: auto;
}

.modal .backdrop {
    background: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    opacity: 0.5;
}

.header .username {
    font-size: 13px;
    text-decoration: none;
    color: #fa675b;
    margin-left: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.table-responsive {
    overflow-x: auto;
    width: 100%;
}

.table-head {
    display: flex;
    padding: 10px;
    background: #f7f7f7;
    margin: 30px 30px 0px 30px;
    font-size: 13px;
    font-weight: bold;
    color: #b7aeb0;
    border-radius: 0px;
}

.table-item span {
    flex: 0 0 25%;
}

.table-item {
    transition: 0.15s ease-in-out all;
    display: flex;
    margin: 0px 30px;
    background: white;
    min-height: 30px;
    padding: 10px;
    line-height: 30px;
    font-size: 13px;
    border-bottom: 1px solid #f7f7f7;
}

@media screen and (max-width: 456px) {
    .username {
        display: none;
    }
    .no-row-mobile {
        flex-direction: column;
    }
    .header {}
    .breadcrumb {
        padding: 20px 65px !important;
    }
    .content {
        padding: 0 !important;
    }
    .table-head {
        margin: 0 !important;
        font-size: 11px;
    }
    .table-responsive {
        min-width: inherit;
    }
    .datepicker {
        margin-left: 0 !important;
        margin-right: 0 !important;
        flex: 1;
        box-sizing: border-box;
        height: 50px !important;
    }
    .table-head span {
        text-align: center;
    }
    .mobile-light {
        justify-content: center;
    }
    .no-flex-mobile {
        display: inherit !important;
    }
    .seatplan {
        text-align: center;
    }
    input[type="date"] {
        text-align: center !important;
    }
}

@media screen and (max-width: 900px) {
    .header {
        padding: 0px 15px;
    }
    .table-responsive {
        flex: inherit !important;
    }
    .table-item {
        margin: inherit;
    }
    .desktop-flex {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 1344px) {
    .flex-dashboard {
        flex-direction: column;
    }
}

@media screen and (max-width: 1700px) {
    .chart-column {
        flex-direction: column;
    }
}

@media screen and (max-width: 900px) {
    .mobile-column {
        flex-direction: column !important;
    }
}

@media screen and (min-width: 900px) {
    .desktop-flex {
        display: flex;
        width: 100% !important;
        flex-wrap: wrap;
    }
    .desktop-flex-item {
        flex: 1 0 25%;
    }
}

app-survey {
    position: fixed;
    right: 0px;
    bottom: 0px;
}

.introjs-overlay {
    opacity: 0.4 !important;
}

app-my-bookings {
    width: 100%;
}

.onlyprint {
    display: none;
}

@media print {
    .sidebar,
    .header,
    .noprint {
        display: none !important;
    }
    app-survey {
        display: none;
    }
    .whiteprint {
        background: white !important;
        opacity: 1 !important;
    }
    .onlyprint {
        display: flex;
    }
}

.hero-bg {
    background: url("assets/img/hero-1-bg-img.png");
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

::-webkit-scrollbar {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
}

.afu-select-btn {
    border: 1px solid #e26363;
    height: 20px;
    position: relative;
    top: -50px;
    width: 100px;
    place-self: center;
    line-height: 20px;
    border-radius: 0px;
    color: #e26363 !important;
    cursor: pointer;
    padding: 5px;
}

.afu-upload-btn {
    border: none;
    display: none !important;
    height: 35px;
    width: 100%;
    border-radius: 0px;
    background: var(--secondary) !important;
    color: white !important;
    cursor: pointer;
    padding: 5px;
}

.afu-valid-file .textOverflow .text-primary,
.afu-valid-file .sizeC,
.afu-valid-file .col-xs-1 {
    display: none !important;
}

.afu-valid-file .col-xs-3 {
    margin-top: 0;
}

.afu-progress-bar {
    background: var(--secondary) !important;
    color: white !important;
    height: 30px !important;
    border-radius: 10px !important;
    line-height: 30px !important;
}

#default {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    text-align-last: center;
}

app-file-upload {
    width: 100%;
    height: 100% !important;
}

.constraints-info {
    font-size: 12px !important;
    font-style: normal !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.dragNDrop .div1 {
    border: 2px dashed #e4e4e4 !important;
    height: 100% !important;
    line-height: 100px !important;
    min-height: 200px;
    width: inherit !important;
}

#dragNDrop {
    flex: 1;
    width: 100%;
}

#dragNDrop div:first-of-type {
    height: 100%;
}

.dragNDrop .div1 p {
    color: #525252 !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    width: 200px !important;
    text-align: center !important;
    margin: auto auto !important;
    margin-top: 30px !important;
    height: 100% !important;
    position: relative !important;
    top: 25% !important;
}

.dragNDropBtmPad {
    padding-bottom: inherit !important;
}

app-map-upload {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .content {
        min-width: fit-content;
    }
    .mobile-column {
        flex-direction: column !important;
    }
}

.cc-link {
    color: var(--primary) !important;
}

.cc-revoke.cc-bottom {
    display: none;
}

.bg-img {
    background-color: #ffffff;
    padding: 25px 25px 0px 25px;
}

.content.bg-img {
    border-bottom: 1px solid #e8e8e8;
}

tr {
    height: 40px;
}

h1 {
    font-size: 16px;
}

.alt-button {
    height: 40px;
    padding: 0px 20px;
    background: white;
    color: var(--primary);
    cursor: pointer;
    min-width: 100px;
    border: 1px solid var(--primary);
    border-radius: 5px;
    font-weight: 700;
}

.alt-button:hover,
.cta-button:hover,
button.create:hover {
    filter: brightness(0.9);
}

.cta-button {
    border: none;
    height: 40px;
    padding: 0px 20px;
    background: var(--primary);
    color: white;
    cursor: pointer;
    min-width: 100px;
    border-radius: 5px;
    font-weight: 700;
}

.scale-in-center {
    -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    90% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    90% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.modal .content {
    top: 20vh !important;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
    margin: auto !important;
    -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modal .content.onboarding {
    top: 10vh !important;
}

.fa,
.fas,
.far {
    color: transparent !important;
    -webkit-text-stroke-width: 0.7px;
    -webkit-text-stroke-color: var(--accent);
}

.fa-search-location,
.fa-chevron-down,
.fa-chevron-up,
.fa-chevron-left,
.fa-chevron-right {
    -webkit-text-stroke-color: var(--secondary);
}

.fa-exclamation-triangle {
    color: #fcb045 !important;
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: var(--primary);
}

button .fa,
button .fas,
button .far {
    color: white !important;
    -webkit-text-stroke-width: 0.7px;
    -webkit-text-stroke-color: var(--primary);
}

ion-slide:nth-child(n+2) {
    display: none;
}

.monthview-selected {
    background: var(--primary) !important;
}

.table-bordered>thead>tr>th {
    border-bottom-width: 0px !important;
}

.table-bordered>thead>tr>th,
.table-bordered,
.table-bordered>tbody>tr>td {
    border: none !important;
    vertical-align: auto !important;
}

.table>tbody>tr>td {
    vertical-align: middle !important;
}

.monthview-primary-with-event {
    color: black !important;
    background: white !important;
}

.monthview-primary-with-event:after {
    content: "\a";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: var(--accent);
    display: flex;
    margin: auto;
}

.monthview-primary-with-event.monthview-selected {
    background: var(--primary) !important;
    color: white !important;
}

.event-detail-container {
    border-top: none !important;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
}

.event-detail-container ion-item {
    padding: 15px 25px;
    border: 1px solid var(--third);
    border-left: 5px solid var(--secondary);
    margin-bottom: 5px;
    max-width: 88vw;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-detail-container ion-item:last-of-type {
    margin-bottom: 55px;
}

button.rebrand.primary {
    padding: 15px 25px;
    margin: auto;
    width: 250px;
    margin-top: 15px;
    background: var(--accent);
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

button.rebrand.primary.red {
    background: #da4747 !important;
}

button.rebrand:hover {
    filter: brightness(0.9);
}

button.rebrand:focus {
    outline: 0;
}

button.rebrand.secondary {
    padding: 15px 25px;
    margin: auto;
    width: 250px;
    margin-top: 15px;
    border: 1px solid var(--primary);
    border-radius: 5px;
    color: var(--primary);
    font-weight: 700;
    cursor: pointer;
    background: none;
}

app-tab-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: white;
}

.flex {
    display: flex;
}

.center-items {
    align-items: center;
    justify-content: center;
    padding: 15px 50px;
}

.center-items i {
    cursor: pointer;
}

.title {
    flex: 1;
    text-align: center;
    cursor: pointer;
}

.title span {
    background: none;
    padding: 5px;
    font-weight: 700;
    border-radius: 10px;
}

.column {
    flex-direction: column;
}

.dot {
    width: 10px;
    background: var(--accent);
    height: 10px;
    border-radius: 100px;
    transform: scale(0.7);
    margin-top: 5px;
}

.room-and-time {
    color: var(--third);
    margin-bottom: 5px;
}

.schedule-item-title {
    font-weight: bold;
    color: var(--secondary);
}

.schedule-item-user {
    font-size: 12px;
    color: var(--third);
}

.schedule {
    font-size: 20px;
    font-weight: bold;
    padding: 23px 15px 0px;
}

.schedule-item:last-of-type {
    margin-bottom: 49px;
}

@media screen and (min-width: 1024px) {
    .calendar-desktop {
        display: flex;
        height: calc(100vh - 100px);
    }
    .hide-desktop {
        display: none !important
    }
    .hide-desktop-hidden {
        display: hidden;
    }
    .calendar-desktop-flex-25 {
        flex: 0 0 25%;
        border-right: 1px solid var(--fourth);
        display: flex;
        flex-direction: column;
    }
    .desktop-flex-1 {
        flex: 1;
    }
}

@media screen and (max-width: 1023px) {
    .only-desktop {
        display: none !important;
    }
}

app-modal-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-elevation-z8 {
    box-shadow: none !important;
}